import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {logEvent, logException, logTiming } from '../analytics';

const BaseContext = createContext(null);

export const useBase = () => useContext(BaseContext);

export const BaseProvider = ({ children }) => {
    const trackEvent = (User,Action,Target) => {
      logEvent(User, Action, Target, 1);
    };

    const trackException = (desc) => {
      logException(desc, true);
    };

    const trackTiming = (Target,Method,Type,start,end) => {
      logTiming(Target, Method, end - start, Type);
    };
    const [loading, setLoading] = useState(true);
    const [accepted, setAccepted] = useState(false); // Összeg állítása
    const [naptarLoaded,setNaparLoaded] = useState(false);
    const [toGal,setToGal] = useState(false);
    const [toKap,setToKap] = useState(false);
    const [toPri,setToPri] = useState(false);
    const [toASZF,setToASZF] = useState(false);
    const [on,setOn] = useState(false);
    const [on2,setOn2] = useState(false);
    const [chose,setChose] = useState(false);
    const [popMessage,setPopMassage] = useState("What?");
    const [popMessageCol,setPopMassageCol] = useState("green");
    const [tcolor,setTColor] = useState('black');
    const [name, setName] = useState('');
    const [name2, setName2] = useState('');
    const [name3, setName3] = useState('');
    const [name4, setName4] = useState('');
    const [name5, setName5] = useState('');
    const [name6, setName6] = useState('');
    const [cegname, setCegName] = useState('');
    const [adoszam, setAdoSzam] = useState('');
    const [postalcode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [adress,setAdress] = useState('');
    const [country,setCountry] = useState('Magyarország');
    const [email, setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [comment,setComment] = useState('');
    const [hnum, setHNum] = useState(1);
    const [im, setIm] = useState(5);
    const [selectedMenu, setSelectedMenu] = useState('stripe');
    const [predefinedToken,setpredefinedToken] = useState("KurukHillAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120");
    const [cartData,setCartData] = useState([]);
    const [selectedRangeCP, setSelectedRangeCP] = useState({ start: null, end: null });
    const [selectedRangeGP, setSelectedRangeGP] = useState({ start: null, end: null });
    const [selectedRangeMM, setSelectedRangeMM] = useState({ start: null, end: null });
    const [selectedRange, setSelectedRange] = useState({ start: null, end: null });
    const [selectedRange2, setSelectedRange2] = useState({ start: null, end: null });
    const [selectedRange3, setSelectedRange3] = useState({ start: null, end: null });
    const [selectedRangeCPAdmin, setSelectedRangeCPAdmin] = useState({ start: null, end: null });
    const [selectedRangeGPAdmin, setSelectedRangeGPAdmin] = useState({ start: null, end: null });
    const [selectedRangeMMAdmin, setSelectedRangeMMAdmin] = useState({ start: null, end: null });
    const [selectedRangeAdmin, setSelectedRangeAdmin] = useState({ start: null, end: null });
    const [selectedRange2Admin, setSelectedRange2Admin] = useState({ start: null, end: null });
    const [selectedRange3Admin, setSelectedRange3Admin] = useState({ start: null, end: null });
    const [done, setDone] = useState(false);
    const [doneAdmin, setDoneAdmin] = useState(false);
    const [pstate,setPstate] = useState(0);
    const [nameStyle,setNameStyle] = useState('2px solid rgba(93, 115, 41, 1)');
    const [nameStyle2,setNameStyle2] = useState('2px solid rgba(93, 115, 41, 1)');
    const [nameStyle3,setNameStyle3] = useState('2px solid rgba(93, 115, 41, 1)');
    const [nameStyle4,setNameStyle4] = useState('2px solid rgba(93, 115, 41, 1)');
    const [nameStyle5,setNameStyle5] = useState('2px solid rgba(93, 115, 41, 1)');
    const [nameStyle6,setNameStyle6] = useState('2px solid rgba(93, 115, 41, 1)');
    const [postalStyle,setPostalStyle] = useState('2px solid rgba(93, 115, 41, 1)');
    const [cityStyle,setCityStyle] = useState('2px solid rgba(93, 115, 41, 1)');
    const [adressStyle,setAdressStyle] = useState('2px solid rgba(93, 115, 41, 1)');
    const [countryStyle,setCountryStyle] = useState('2px solid rgba(93, 115, 41, 1)');
    const [emailStyle,setEmailStyle] = useState('2px solid rgba(93, 115, 41, 1)');
    const [phoneStyle,setPhoneStyle] = useState('2px solid rgba(93, 115, 41, 1)');
    const [MMprice,setMMprice] = useState(0);
    const [MMpriceBF,setMMpriceBF] = useState (0);
    const [MMpriceSTR,setMMpriceSTR] = useState("");
    const [CPprice,setCPprice] = useState(0);
    const [CPpriceBF,setCPpriceBF] = useState (0);
    const [CPpriceSTR,setCPpriceSTR] = useState("");
    const [GPprice,setGPprice] = useState(0);
    const [GPpriceBF,setGPpriceBF] = useState (0);
    const [GPpriceSTR,setGPpriceSTR] = useState("");
    const [GPPicState,setGPPicState] = useState(0);
    const [GPPic,setGPPic] = useState({});
    const [CPPicState,setCPPicState] = useState(0);
    const [CPPic,setCPPic] = useState({});
    const [MMPicState,setMMPicState] = useState(0);
    const [MMPic,setMMPic] = useState({});
    const [crystalHouseImages, setCrystalHouseImages] = useState([]);
    const [greenHouseImages, setGreenHouseImages] = useState([]);
    const [mangoHouseImages, setMangoHouseImages] = useState([]);
    const [useButton, setUseButton] = useState(false);
    const [progressRes,setProgressRes] = useState(false);

    const changeHousePic = (house, dir) => {
      if (house === "CP") {
        let state = 0;
        if (dir === "R") {
          state = (CPPicState !== 9) ? CPPicState + 1 : 0;
        } else if (dir === "L") {
          state = (CPPicState !== 0) ? CPPicState - 1 : 9;
        } else if (dir === "RES") {
          state = 0;
        }
        setCPPicState(state);
      } else if (house === "GP") {
        let state = 0;
        if (dir === "R") {
          state = (GPPicState !== 8) ? GPPicState + 1 : 0;
        } else if (dir === "L") {
          state = (GPPicState !== 0) ? GPPicState - 1 : 8;
        } else if (dir === "RES") {
          state = 0;
        }
        setGPPicState(state);
      } else if (house === "MM") {
        let state = 0;
        if (dir === "R") {
          state = (MMPicState !== 14) ? MMPicState + 1 : 0;
        } else if (dir === "L") {
          state = (MMPicState !== 0) ? MMPicState - 1 : 14;
        } else if (dir === "RES") {
          state = 0;
        }
        setMMPicState(state);
      }
      setIsMenuOpen(false);
    };

    const closePopup = () => {setOn(false);
      document.documentElement.style.overflowY = 'scroll';
      clearRanges();
        setDone(false);
        setPstate(0);
        setName('');setName2('');setName3('');setName4('');setName5('');setName6('');setCegName('');
        setAdoSzam('');setPostalCode('');setCity('');setAdress('');setCountry('');setEmail('');setPhone('');setComment('');
    }
    const openPopup = () =>  {
      setOn(true);
      document.documentElement.style.overflowY = 'hidden';
      setNameStyle('2px solid rgba(93, 115, 41, 1)');
      setNameStyle2('2px solid rgba(93, 115, 41, 1)');
      setNameStyle3('2px solid rgba(93, 115, 41, 1)');
      setNameStyle4('2px solid rgba(93, 115, 41, 1)');
      setNameStyle5('2px solid rgba(93, 115, 41, 1)');
      setNameStyle6('2px solid rgba(93, 115, 41, 1)');
      setPostalStyle('2px solid rgba(93, 115, 41, 1)');
      setCityStyle('2px solid rgba(93, 115, 41, 1)');
      setAdressStyle('2px solid rgba(93, 115, 41, 1)');
      setCountryStyle('2px solid rgba(93, 115, 41, 1)');
      setEmailStyle('2px solid rgba(93, 115, 41, 1)');
      setPhoneStyle('2px solid rgba(93, 115, 41, 1)');
     }
    const closePopup2 = () => {setOn2(false);}
    const openPopup2 = () =>  {setOn2(true); }
    const [amount,setAmount] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [device,setDevice] = useState("PC");
    const [toggles, setToggles] = useState({
        CrystalPower: false,
        GreenPassion: false,
        MagicMango: false,
      });

      const checkToggleData = (key) => {
        switch (key) {
          case "CP":
            return toggles.hasOwnProperty("CrystalPower") ? toggles.CrystalPower.toString().toUpperCase() : "NO";
          case "GP":
            return toggles.hasOwnProperty("GreenPassion") ? toggles.GreenPassion.toString().toUpperCase() : "NO";
          case "MM":
            return toggles.hasOwnProperty("MagicMango") ? toggles.MagicMango.toString().toUpperCase() : "NO";
          default:
            return "NO";
        }
      };
    
      const clearRanges = () => {
        setSelectedRange({ start: null, end: null });
        setSelectedRange2({ start: null, end: null });
        setSelectedRange3({ start: null, end: null });
        setSelectedRangeGP({ start: null, end: null });
        setSelectedRangeMM({ start: null, end: null });
        setSelectedRangeCP({ start: null, end: null });
      }
      const clearRangesAdmin = () => {
        setSelectedRangeAdmin({ start: null, end: null });
        setSelectedRange2Admin({ start: null, end: null });
        setSelectedRange3Admin({ start: null, end: null });
        setSelectedRangeGPAdmin({ start: null, end: null });
        setSelectedRangeMMAdmin({ start: null, end: null });
        setSelectedRangeCPAdmin({ start: null, end: null });
      }
      const handleToggle = (house) => {
        setToggles((prevToggles) => ({
          ...prevToggles,
          [house]: !prevToggles[house],
        }));
          
      };
      const [togg,setTogg] = useState(false);
      const handleToggle2 = () => {
        setTogg(!togg);
      }; 
      const countTrues = () => {
        return Object.values(toggles).filter(value => value === true).length;
      };
      const getToggleString = () => {
        const keys = ['CrystalPower', 'GreenPassion', 'MagicMango'];
        return keys.map(key => (toggles[key] ? '1' : '0')).join('-');
      };
      const getColor = (house) => {
        let color = "";
          if (house === "GreenPassion") {
            color = 'rgb(93, 115, 41)';
          }
          else if (house === "MagicMango") {
            color = 'rgb(255, 191, 52)';
          }
          else if (house === "CrystalPower") {
            color = 'rgb(246, 38, 129)';
          }
        return color;
      }
      const getDates = (house) => {
        let dates = "";
          if (house === "GreenPassion") {
            dates = selectedRange.start.split('-')[0]+"."+selectedRange.start.split('-')[1]+"."+selectedRange.start.split('-')[2]+" - "+selectedRange.end.split('-')[0]+"."+selectedRange.end.split('-')[1]+"."+selectedRange.end.split('-')[2]
          }
          else if (house === "MagicMango") {
            dates = selectedRange2.start.split('-')[0]+"."+selectedRange2.start.split('-')[1]+"."+selectedRange2.start.split('-')[2]+" - "+selectedRange2.end.split('-')[0]+"."+selectedRange2.end.split('-')[1]+"."+selectedRange2.end.split('-')[2]
          }
          else if (house === "CrystalPower") {
            dates = selectedRange3.start.split('-')[0]+"."+selectedRange3.start.split('-')[1]+"."+selectedRange3.start.split('-')[2]+" - "+selectedRange3.end.split('-')[0]+"."+selectedRange3.end.split('-')[1]+"."+selectedRange3.end.split('-')[2]
          }
        return dates;
      }
      const getDatesAdmin = (house) => {
        let dates = "";
          if (house === "GreenPassion" && selectedRangeAdmin.start !== null && selectedRangeAdmin.end !== null) {
            dates = selectedRangeAdmin.start.split('-')[0]+"."+selectedRangeAdmin.start.split('-')[1]+"."+selectedRangeAdmin.start.split('-')[2]+" - "+selectedRangeAdmin.end.split('-')[0]+"."+selectedRangeAdmin.end.split('-')[1]+"."+selectedRangeAdmin.end.split('-')[2]
          }
          else if (house === "MagicMango" && selectedRange2Admin.start !== null && selectedRange2Admin.end !== null) {
            dates = selectedRange2Admin.start.split('-')[0]+"."+selectedRange2Admin.start.split('-')[1]+"."+selectedRange2Admin.start.split('-')[2]+" - "+selectedRange2Admin.end.split('-')[0]+"."+selectedRange2Admin.end.split('-')[1]+"."+selectedRange2Admin.end.split('-')[2]
          }
          else if (house === "CrystalPower" && selectedRange3Admin.start !== null && selectedRange3Admin.end !== null) {
            dates = selectedRange3Admin.start.split('-')[0]+"."+selectedRange3Admin.start.split('-')[1]+"."+selectedRange3Admin.start.split('-')[2]+" - "+selectedRange3Admin.end.split('-')[0]+"."+selectedRange3Admin.end.split('-')[1]+"."+selectedRange3Admin.end.split('-')[2]
          }
        return dates;
      }
      const getToggles = () => {
        if (selectedRange3.end === null && selectedRange.end === null && selectedRange2.end !== null) {
          setToggles({
            MagicMango: false,
          })
          setHNum(1);
        }
        else if (selectedRange3.end === null && selectedRange.end !== null && selectedRange2.end === null) {
          setToggles({
            GreenPassion: false,
          })
          setHNum(1);
        }
        else if (selectedRange3.end !== null && selectedRange.end === null && selectedRange2.end === null) {
          setToggles({
            CrystalPower: false,
          })
          setHNum(1);
        }
        else if (selectedRange3.end !== null && selectedRange.end !== null && selectedRange2.end === null) {
          setToggles({
            CrystalPower: false,
            GreenPassion: false,
          })
          setHNum(2);
        }
        else if (selectedRange3.end !== null && selectedRange.end === null && selectedRange2.end !== null) {
          setToggles({
            CrystalPower: false,
            MagicMango: false,
          })
          setHNum(2);
        }
        else if (selectedRange3.end === null && selectedRange.end !== null && selectedRange2.end !== null) {
          setToggles({
            GreenPassion: false,
            MagicMango: false,
          })
          setHNum(2);
        }
        else {
          setToggles({
            CrystalPower: false,
            GreenPassion: false,
            MagicMango: false,
          })
          setHNum(3);
        }
      }
      const extractDate = (dateStr) => {
        if (dateStr !== null) {
            return dateStr.split('-').slice(0, 3).join('-');
        } else {return "";}
      };
      const calcIm = (c) => {
        if (c === 2) {return '/img3/kep-1b.JPG'}
        else if (c === 1) {return '/img3/kep-2b.jpg'}
        else if (c === 3) {return '/img3/kep-3b.jpg'}
        else if (c === 4) {return '/img3/kep-4b.jpg'}
        else if (c === 5) {return '/img3/kep-5b.jpg'}
        else if (c === 6) {return '/img3/kep-6b.jpg'}
        else if (c === 7) {return '/img3/kep-7b.jpg'}
        else if (c === 8) {return '/img3/kep-8b.jpg'}
        else if (c === 9) {return '/img3/kep-9b.jpg'}
        else if (c === 10) {return '/img3/kep-10b.jpg'}
        else if (c === 11) {return '/img3/kep-11b.jpg'}
        else if (c === 12) {return '/img3/kep-12b.jpg'}
        else if (c === 13) {return '/img3/kep-13b.jpg'}
        else if (c === 14) {return '/img/kep-14b.jpg'}
        else {return '/img/kep-1b.jpg'}
      }
      

      const createBasket = () => {
        // Termékek létrehozása a context adatok alapján
        const products = [
          {
            ID: 0,
            cikkszam: 1,
            megnevezes: `${extractDate(selectedRange3.start)}=${extractDate(selectedRange3.end)}`
          },
          {
            ID: 0,
            cikkszam: 2,
            megnevezes: `${extractDate(selectedRange.start)}=${extractDate(selectedRange.end)}`
          },
          {
            ID: 0,
            cikkszam: 3,
            megnevezes: `${extractDate(selectedRange2.start)}=${extractDate(selectedRange2.end)}`
          }
        ];
      
        // Amounts létrehozása a toggles alapján
        const amounts = [
            toggles.CrystalPower !== null ? toggles.CrystalPower ? 1 : 0 : 0,
            toggles.GreenPassion !== null ? toggles.GreenPassion ? 1 : 0 : 0,
            toggles.MagicMango !== null ? toggles.MagicMango ? 1 : 0 : 0
        ];
      
        // Basket objektum létrehozása
        const basket = {
          products: products,
          amounts: amounts,
          id: 0
        };
      
        return basket;
      };
      
      
      const getAmount = () => {
        let rs1 = "NO";let rs2 = "NO";let rs3 = "NO";
        let re1 = "NO";let re2 = "NO";let re3 = "NO";
        if (selectedRange.start !== null && selectedRange.end !== null){
          rs1 = selectedRange.start.split('-')[0]+"-"+selectedRange.start.split('-')[1]+"-"+selectedRange.start.split('-')[2];
          re1 = selectedRange.end.split('-')[0]+"-"+selectedRange.end.split('-')[1]+"-"+selectedRange.end.split('-')[2];
        }
        if (selectedRange2.start !== null && selectedRange2.end !== null){
          rs2 = selectedRange2.start.split('-')[0]+"-"+selectedRange2.start.split('-')[1]+"-"+selectedRange2.start.split('-')[2];
          re2 = selectedRange2.end.split('-')[0]+"-"+selectedRange2.end.split('-')[1]+"-"+selectedRange2.end.split('-')[2];
        }
        if (selectedRange3.start !== null && selectedRange3.end !== null){
          rs3 = selectedRange3.start.split('-')[0]+"-"+selectedRange3.start.split('-')[1]+"-"+selectedRange3.start.split('-')[2];
          re3 = selectedRange3.end.split('-')[0]+"-"+selectedRange3.end.split('-')[1]+"-"+selectedRange3.end.split('-')[2];
        }
        const breakfast = getToggleString();
        const start = performance.now();
        axios.get(`https://kurukhill.hu:41808/reservation/getPrice?startDateCP=${rs3}&endDateCP=${re3}&startDateMM=${rs2}&endDateMM=${re2}&startDateGP=${rs1}&endDateGP=${re1}&breakfast=${breakfast}`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          setAmount(response.data)
          const end = performance.now();
          trackTiming('API', 'fetchData','API Request',start,end)
          console.log('Response:', response);
          trackEvent("Function","Backend-Call","Amount");
        })
        .catch(error => {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
              trackException('Payment Error:'+ error.response.data);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Payment Error:', error.request);
              trackException('Payment Error:'+ error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
              trackException('Error:'+ error.message);
          }
        });
      }

      const getAmountDetailed = () => {
        let rs1 = "NO";let rs2 = "NO";let rs3 = "NO";
        let re1 = "NO";let re2 = "NO";let re3 = "NO";
        if (selectedRange.start !== null && selectedRange.end !== null){
          rs1 = selectedRange.start.split('-')[0]+"-"+selectedRange.start.split('-')[1]+"-"+selectedRange.start.split('-')[2];
          re1 = selectedRange.end.split('-')[0]+"-"+selectedRange.end.split('-')[1]+"-"+selectedRange.end.split('-')[2];
        }
        if (selectedRange2.start !== null && selectedRange2.end !== null){
          rs2 = selectedRange2.start.split('-')[0]+"-"+selectedRange2.start.split('-')[1]+"-"+selectedRange2.start.split('-')[2];
          re2 = selectedRange2.end.split('-')[0]+"-"+selectedRange2.end.split('-')[1]+"-"+selectedRange2.end.split('-')[2];
        }
        if (selectedRange3.start !== null && selectedRange3.end !== null){
          rs3 = selectedRange3.start.split('-')[0]+"-"+selectedRange3.start.split('-')[1]+"-"+selectedRange3.start.split('-')[2];
          re3 = selectedRange3.end.split('-')[0]+"-"+selectedRange3.end.split('-')[1]+"-"+selectedRange3.end.split('-')[2];
        }
        const breakfast = getToggleString();
        const start = performance.now();
        axios.get(`https://kurukhill.hu:41808/reservation/getPriceHouse?startDateCP=${rs3}&endDateCP=${re3}&startDateMM=${rs2}&endDateMM=${re2}&startDateGP=${rs1}&endDateGP=${re1}&breakfast=${breakfast}&house=CrystalPower`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          setCPprice(response.data)
          trackEvent("Function","Backend-Call","CP-Price");
          const end = performance.now();
          trackTiming('API', 'fetchData','API Request',start,end)
        })
        .catch(error => {
          if (error.response) {
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
              trackException('Payment Error:'+ error.response.data);
          } else if (error.request) {
              console.error('Payment Error:', error.request);
              trackException('Payment Error:'+ error.request);
          } else {
              console.error('Error', error.message);
              trackException('Error:'+ error.message);
          }
        });
        const start2 = performance.now();
        axios.get(`https://kurukhill.hu:41808/reservation/getPriceHouse?startDateCP=${rs3}&endDateCP=${re3}&startDateMM=${rs2}&endDateMM=${re2}&startDateGP=${rs1}&endDateGP=${re1}&breakfast=${breakfast}&house=GreenPassion`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          setGPprice(response.data)
          trackEvent("Function","Backend-Call","GP-Price");
          const end2 = performance.now();
          trackTiming('API', 'fetchData','API Request',start2,end2)
        })
        .catch(error => {
          if (error.response) {
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
              trackException('Payment Error:'+ error.response.data);
          } else if (error.request) {
              console.error('Payment Error:', error.request);
              trackException('Payment Error:'+ error.request);
          } else {
              console.error('Error', error.message);
              trackException('Payment Error:'+ error.message);
          }
        });
        const start3 = performance.now();
        axios.get(`https://kurukhill.hu:41808/reservation/getPriceHouse?startDateCP=${rs3}&endDateCP=${re3}&startDateMM=${rs2}&endDateMM=${re2}&startDateGP=${rs1}&endDateGP=${re1}&breakfast=${breakfast}&house=MagicMango`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          setMMprice(response.data)
          trackEvent("Function","Backend-Call","MM-Price");
          const end3 = performance.now();
          trackTiming('API', 'fetchData','API Request',start3,end3)
        })
        .catch(error => {
          if (error.response) {
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
              trackException('Payment Error:'+ error.response.data);
          } else if (error.request) {
              console.error('Payment Error:', error.request);
              trackException('Payment Error:'+ error.request);
          } else {
              console.error('Error', error.message);
              trackException('Payment Error:'+ error.message);
          }
        });
        const start4 = performance.now();
        axios.get(`https://kurukhill.hu:41808/reservation/getPriceHouseBF?startDateCP=${rs3}&endDateCP=${re3}&startDateMM=${rs2}&endDateMM=${re2}&startDateGP=${rs1}&endDateGP=${re1}&breakfast=${breakfast}&house=CrystalPower`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          setCPpriceBF(response.data)
          trackEvent("Function","Backend-Call","CP-Price-BF");
          const end4 = performance.now();
          trackTiming('API', 'fetchData','API Request',start4,end4)
        })
        .catch(error => {
          if (error.response) {
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
              trackException('Payment Error:'+ error.response.data);
          } else if (error.request) {
              console.error('Payment Error:', error.request);
              trackException('Payment Error:'+ error.request);
          } else {
              console.error('Error', error.message);
              trackException('Payment Error:'+ error.message);
          }
        });
        const start5 = performance.now();
        axios.get(`https://kurukhill.hu:41808/reservation/getPriceHouseBF?startDateCP=${rs3}&endDateCP=${re3}&startDateMM=${rs2}&endDateMM=${re2}&startDateGP=${rs1}&endDateGP=${re1}&breakfast=${breakfast}&house=GreenPassion`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          setGPpriceBF(response.data)
          trackEvent("Function","Backend-Call","GP-Price-BF");
          const end5 = performance.now();
          trackTiming('API', 'fetchData','API Request',start5,end5);
        })
        .catch(error => {
          if (error.response) {
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
              trackException('Payment Error:'+ error.response.data);
          } else if (error.request) {
              console.error('Payment Error:', error.request);
              trackException('Payment Error:'+ error.request);
          } else {
              console.error('Error', error.message);
              trackException('Payment Error:'+ error.message);
          }
        });
        const start6 = performance.now();
        axios.get(`https://kurukhill.hu:41808/reservation/getPriceHouseBF?startDateCP=${rs3}&endDateCP=${re3}&startDateMM=${rs2}&endDateMM=${re2}&startDateGP=${rs1}&endDateGP=${re1}&breakfast=${breakfast}&house=MagicMango`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          setMMpriceBF(response.data)
          const end6 = performance.now();
          trackTiming('API', 'fetchData','API Request',start6,end6);
          trackEvent("Function","Backend-Call","MM-Price-BF");
        })
        .catch(error => {
          if (error.response) {
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
              trackException('Payment Error:'+ error.response.data);
          } else if (error.request) {
              console.error('Payment Error:', error.request);
              trackException('Payment Error:'+ error.request);
          } else {
              console.error('Error', error.message);
              trackException('Payment Error:'+ error.message);
          }
        });
      }

      useEffect(() => {
        const formattedCPpriceDiff = (CPprice - CPpriceBF).toLocaleString('fr-FR');
        const formattedCPpriceBF = CPpriceBF.toLocaleString('fr-FR');
        const str1 = (CPprice !== 0 ? `${formattedCPpriceDiff}${CPpriceBF === 0 ? "" : (" + " + formattedCPpriceBF)}` : "NO");
        setCPpriceSTR(str1);

        const formattedGPpriceDiff = (GPprice - GPpriceBF).toLocaleString('fr-FR');
        const formattedGPpriceBF = GPpriceBF.toLocaleString('fr-FR');
        const str2 = (GPprice !== 0 ? `${formattedGPpriceDiff}${GPpriceBF === 0 ? "" : (" + " + formattedGPpriceBF)}` : "NO");
        setGPpriceSTR(str2);

        const formattedMMpriceDiff = (MMprice - MMpriceBF).toLocaleString('fr-FR');
        const formattedMMpriceBF = MMpriceBF.toLocaleString('fr-FR');
        const str3 = (MMprice !== 0 ? `${formattedMMpriceDiff}${MMpriceBF === 0 ? "" : (" + " + formattedMMpriceBF)}` : "NO");
        setMMpriceSTR(str3);
      }, [CPprice,CPpriceBF,GPprice,GPpriceBF,MMprice,MMpriceBF]);
      useEffect(() => {
        getAmountDetailed();
        trackEvent("Function","Backend-Call","Prices");
    }, [toggles]);

      const MakeCode = () => {
        let code = "";
        if (selectedRange.start !== null && selectedRange.end !== null) {
          code += "GP"+selectedRange.start.split("-")[0].slice(-2)+selectedRange.start.split("-")[1]+selectedRange.start.split("-")[2];
        }
        if (selectedRange2.start !== null && selectedRange2.end !== null) {
          code += "MM"+selectedRange2.start.split("-")[0].slice(-2)+selectedRange2.start.split("-")[1]+selectedRange2.start.split("-")[2];
        }
        if (selectedRange3.start !== null && selectedRange3.end !== null) {
          code += "CP"+selectedRange3.start.split("-")[0].slice(-2)+selectedRange3.start.split("-")[1]+selectedRange3.start.split("-")[2];
        }
        trackEvent("Function","Generate Code","Reservation");
        return code;
      }
      const CloseMessagePopup = () => {
        clearRanges();
        setDone(false);
        setPstate(0);
        setName('');setName2('');setName3('');setName4('');setName5('');setName6('');setCegName('');
        setAdoSzam('');setPostalCode('');setCity('');setAdress('');setCountry('');setEmail('');setPhone('');setComment('');
        closePopup();
      }


    // Context value that will be passed to consumers
    const contextValue = {
        closePopup,on,setOn,openPopup,pstate,setPstate,done, setDone,amount,setAmount,closePopup2,on2,setOn2,openPopup2,chose,setChose,im, setIm,calcIm,
        selectedRangeCP, setSelectedRangeCP,selectedRangeGP, setSelectedRangeGP,selectedRangeMM, setSelectedRangeMM,toggles, setToggles,handleToggle,getToggles,
        name, setName,selectedMenu, setSelectedMenu,cartData,setCartData,createBasket,isMenuOpen, setIsMenuOpen,
        selectedRange, setSelectedRange,selectedRange2, setSelectedRange2,selectedRange3, setSelectedRange3,clearRanges,device,setDevice,
        toGal,setToGal,toKap,setToKap,toPri,setToPri,getAmount,toASZF,setToASZF,
        name2,setName2,name3,setName3,name4,setName4,name5,setName5,name6,setName6,hnum,setHNum,getColor,
        cegname,setCegName,adoszam,setAdoSzam,postalcode,setPostalCode,city,setCity,adress,setAdress,country,setCountry,email,setEmail,phone,setPhone,comment,setComment,getDates,
        accepted, setAccepted,MakeCode,tcolor,setTColor,popMessageCol,setPopMassageCol,popMessage,setPopMassage,CloseMessagePopup,predefinedToken,clearRangesAdmin,

        getDatesAdmin,selectedRangeCPAdmin, setSelectedRangeCPAdmin, setSelectedRangeGPAdmin, setSelectedRangeMMAdmin,doneAdmin,selectedRangeAdmin, setSelectedRangeAdmin,selectedRange2Admin, setSelectedRange2Admin,selectedRange3Admin, setSelectedRange3Admin,
        nameStyle,setNameStyle,nameStyle2,setNameStyle2,nameStyle3,setNameStyle3,nameStyle4,setNameStyle4,nameStyle5,setNameStyle5,nameStyle6,setNameStyle6,postalStyle,setPostalStyle,
        cityStyle,setCityStyle,adressStyle,setAdressStyle,countryStyle,setCountryStyle,emailStyle,setEmailStyle,phoneStyle,setPhoneStyle,naptarLoaded,setNaparLoaded,
        togg,setTogg,handleToggle2,getToggleString,getAmountDetailed,MMpriceSTR,GPpriceSTR,CPpriceSTR,changeHousePic,CPPic,GPPic,MMPic,GPPicState,CPPicState,MMPicState,
        loading, setLoading,checkToggleData,useButton, setUseButton,progressRes,setProgressRes
    };

    return <BaseContext.Provider value={contextValue}>{children}</BaseContext.Provider>;
};