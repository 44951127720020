import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useBase } from '../contexts/BaseContext';
import axios from 'axios';
import { useLanguage } from '../contexts/LanguageContext';
import { useAnalize } from '../contexts/AnalizeContext';

const CheckoutForm2 = forwardRef((props, ref) => {

    const {predefinedToken,amount,name,cartData,createBasket,clearRanges,closePopup,setPstate,setDone,setName,name2,setName2,name3,setName3,name4,setName4,name5,setName5,name6,setName6,
            cegname,setCegName,adoszam,setAdoSzam,postalcode,setPostalCode,city,setCity,adress,setAdress,country,setCountry,email,setEmail,phone,setPhone,comment,setComment,accepted,setTColor,
            popMessageCol,setPopMassageCol,popMessage,setPopMassage,CloseMessagePopup,MakeCode,togg,checkToggleData,useButton, setUseButton,progressRes,setProgressRes
    } = useBase();
    const stripe = useStripe();
    const elements = useElements();
    const {trackEvent,trackException,trackTiming,updateStatistics} = useAnalize();
    const {translateWeAreWaiting} = useLanguage();

   const handleSubmit = async (event) => {
  event.preventDefault();
  setProgressRes(true);
  if (!accepted) {
    setTColor("red");
    setTimeout(() => setTColor('black'), 3000);
    setProgressRes(false);
    return;
  }

  if (!stripe || !elements) {setProgressRes(false);return;}
  setUseButton(true);

  const cardElement = elements.getElement(CardElement);
      const start = performance.now();
      const result = await stripe.createSource(cardElement);
      console.log("hey");
      const end = performance.now();
      trackTiming('API', 'Stripe','Create Source',start,end)
      if (result.error) {
        setUseButton(false);
        console.error(result.error.message);
        trackException(result.error.message);
        setProgressRes(false);
        return;
      }
        const sourceId = result.source.id;
        const descSTR1 = MakeCode();
        const descSTR2 = togg ? "teljes" : "foglaló";
        const descSTR3 = togg ? amount : amount/2;
        const description = descSTR1 + " - " + descSTR2 + " - "+ descSTR3;
        const amount2 = amount * 100;
        const chargeDetails = {
          source: sourceId,
          amount: togg ? amount2 : amount2/2,
          shippingName: name,
          name2: name2,
          name3: name3,
          name4: name4,
          name5: name5,
          name6: name6,
          cname: cegname,
          watnum: adoszam,
          postalCode: postalcode,
          city: city,
          adress: adress,
          country: country,
          email: email,
          phone: phone,
          comment: comment,
          desc: description,
          basket: cartData
        };

 

  try {
    const url =  "https://kurukhill.hu:41808/api/payments/charge";
    //const url =  "http://localhost:8080/api/payments/charge";
    const res = await axios.post(url, chargeDetails, {
      headers: {
        Authorization: `Bearer ${predefinedToken}`,
        'Content-Type': 'application/json'
      }
    });

    const { clientSecret, status, docid } = res.data;

    if (status === "ACTION_REQUIRED") {
      const confirmResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (confirmResult.error) {
        console.error('Payment confirmation error:', confirmResult.error.message);
        setPopMassage(confirmResult.error.message);
        setPopMassageCol("red");
        trackException(confirmResult.error.message);
        setPstate(3);
        setUseButton(false);
        setProgressRes(false);
        return;
      }

      if (confirmResult.paymentIntent.status === "succeeded") {
        // ✅ success
        setPopMassage(translateWeAreWaiting());
        setPopMassageCol("green");
        trackEvent("Payment", "Success", "Card");
        // ... minden updateStatistics hívás ide
        setPstate(3);
        setProgressRes(false);
      } else {
        console.error('Payment failed with status:', confirmResult.paymentIntent.status);
        setPopMassage("Sikertelen fizetés");
        setPopMassageCol("red");
        setPstate(3);
        setProgressRes(false);
      }

    } else if (status === "succeeded") {
      setPopMassage(translateWeAreWaiting());
      setPopMassageCol("green");
      setPstate(3);
      setProgressRes(false);
      // ... minden updateStatistics hívás ide
    } else {
      setPopMassage("Ismeretlen státusz: " + status);
      setPopMassageCol("red");
      setPstate(3);
      setProgressRes(false);
    }

  } catch (error) {
    const msg = error.response?.data?.message || error.message;
    console.error("Error during payment:", msg);
    setPopMassage(msg);
    setPopMassageCol("red");
    trackException(msg);
    setPstate(3);
    setProgressRes(false);
  }

  setUseButton(false);
  setProgressRes(false);
};

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit({ preventDefault: () => {} });
    }
  }));

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={{ style: { base: { fontSize: '24px', padding: '10px' } } }}/>
    </form>
  );
});

export default CheckoutForm2;